import { rewards } from "@fscrypto/domain";
import { Entity, EntityFactory, EventBus, Store, createStore, useEntity } from "@fscrypto/state-management";
import { Subscription, filter, from, interval, map, race, switchMap, take, tap, throttle, timer } from "rxjs";
import { GlobalEvent, eventBus } from "~/state/events";
import { RewardClient, rewardClient } from "../data/reward-client";

export type EventBusEvent =
  | { type: "GLOBAL.PAYMENT.SUCCESS"; payload: rewards.PaymentRecord }
  | { type: "GLOBAL.PAYMENT.FAILURE"; payload: rewards.PaymentRecord }
  | { type: "GLOBAL.PAYMENT.TIMEOUT"; payload: rewards.PaymentRecord };

export class PaymentRecordEntity implements Entity<rewards.PaymentRecord> {
  public readonly id: string;
  store: Store<rewards.PaymentRecord>;
  statusStore: Store<"idle" | "pending" | "done" | "timeout" | "error">;
  pollSubscription?: Subscription;
  constructor(
    paymentRecord: rewards.PaymentRecord,
    private eventBus: EventBus<GlobalEvent>,
    private client: RewardClient,
  ) {
    this.id = paymentRecord.id;
    this.store = createStore(paymentRecord);
    this.statusStore = createStore(this.determineInitialState(paymentRecord));
  }

  async poll() {
    if (this.pollSubscription) {
      return;
    }
    const check$ = interval(1000).pipe(
      throttle((x) => interval(Math.min(500 * x, 5000))),
      switchMap((_) => from(this.client.checkPaymentStatus(this.id))),
      map((r) => {
        switch (r.status) {
          case "COMPLETED":
            return { type: "SUCCESS", payload: r };
          case "FAILED":
            return { type: "FAILURE", payload: r };
          case "CREATED":
          case "PENDING":
          default:
            return { type: "PENDING", payload: r };
        }
      }),
      tap((event) => {
        if (event.type === "PENDING") {
          this.store.set(event.payload);
          this.statusStore.set("pending");
        }
      }),
      filter((r) => r.type !== "PENDING"),
      take(1),
    );

    const timeout$ = timer(5 * 60 * 1000).pipe(
      map(() => ({
        type: "TIMEOUT",
        payload: this.store.get(),
        error: new Error("Payment checking timed out"),
      })),
    );

    const done$ = race(timeout$, check$);

    this.pollSubscription = done$.subscribe((event) => {
      this.pollSubscription = undefined;
      if (event.type === "TIMEOUT") {
        this.statusStore.set("timeout");
        this.eventBus.send({ type: "GLOBAL.PAYMENT.TIMEOUT", payload: event.payload });
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: {
            title: "Payment timeout",
            type: "error",
            description: "Checking for payment status timed out. Please try again",
          },
        });
        return;
      }

      if (event.type === "SUCCESS") {
        this.statusStore.set("done");
        this.store.set(event.payload);
        this.eventBus.send({ type: "GLOBAL.PAYMENT.SUCCESS", payload: event.payload });
        eventBus.send({ type: "TOAST.NOTIFY", notif: { title: "Payment successful", type: "success" } });
        return;
      }

      if (event.type === "FAILURE") {
        this.statusStore.set("error");
        this.store.set(event.payload);
        this.eventBus.send({ type: "GLOBAL.PAYMENT.FAILURE", payload: event.payload });
        eventBus.send({ type: "TOAST.NOTIFY", notif: { title: "Payment failed", type: "error" } });
        return;
      }
    });
  }

  determineInitialState(record: rewards.PaymentRecord): "idle" | "pending" | "done" | "timeout" | "error" {
    if (
      record.status === "PENDING" ||
      record.status === "QUEUED" ||
      record.status === "CREATED" ||
      record.status === "UNRESOLVED"
    )
      return "pending";
    if (record.status === "COMPLETED") return "done";
    if (record.status === "FAILED") return "error";
    return "idle";
  }
}

class PaymentRecordEntityFactory implements EntityFactory<PaymentRecordEntity> {
  store: Store<PaymentRecordEntity[]>;
  constructor(
    private eventBus: EventBus<GlobalEvent>,
    private client: RewardClient,
  ) {
    this.store = createStore([] as PaymentRecordEntity[]);
  }

  async poll(paymentRecordId: string) {
    const entity = this.store.get().find((p) => p.id === paymentRecordId);
    if (entity) {
      entity.poll();
      return entity;
    } else {
      const paymentRecord = await this.client.checkPaymentStatus(paymentRecordId);
      const newEntity = this.from(paymentRecord);
      this.store.set([...this.store.get(), newEntity]);
      newEntity.poll();
      return newEntity;
    }
  }

  from(paymentRecord: rewards.PaymentRecord) {
    const existing = this.store.get().find((p) => p.id === paymentRecord.id);
    if (existing) {
      return existing;
    }
    const paymentRecordEntity = new PaymentRecordEntity(paymentRecord, this.eventBus, this.client);
    this.store.set([...this.store.get(), paymentRecordEntity]);
    return paymentRecordEntity;
  }

  from$(id: string) {
    return this.store.value$.pipe(
      map((paymentRecords) => paymentRecords.find((p) => p.id === id)),
      filter(Boolean),
    );
  }
}

export const paymentRecordFactory = new PaymentRecordEntityFactory(eventBus, rewardClient);

export const usePaymentRecord = (paymentRecordId: string) => {
  const paymentRecord = useEntity(paymentRecordFactory, paymentRecordId);
  return paymentRecord;
};
