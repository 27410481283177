import { rewards } from "@fscrypto/domain";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";
import { z } from "zod";

export class RewardClient {
  constructor(private client: HttpClient) {}

  async claimReward(payload: rewards.ClaimReward) {
    const response = await this.client.post($path("/api/rewards/claim"), payload, z.object({ paymentId: z.string() }));
    if (!response.paymentId) {
      throw new Error("Unable to claim reward");
    }
    return response.paymentId;
  }

  async checkPaymentStatus(paymentRecordId: string) {
    return await this.client.get($path("/api/rewards/claim/:id", { id: paymentRecordId }), rewards.paymentRecordSchema);
  }

  async getRewards(profileId?: string) {
    return await this.client.get(
      $path(`/api/user-rewards`, profileId ? new URLSearchParams({ profileId }) : undefined),
      rewards.rewardOverviewSchema,
    );
  }
}

export const rewardClient = new RewardClient(new Client());
