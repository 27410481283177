import { rewards } from "@fscrypto/domain";
import { createAsyncStore, useObservableValue } from "@fscrypto/state-management";
import { RewardOverview } from "node_modules/@fscrypto/domain/src/rewards/user-rewards";
import { useEvent } from "~/state/events";
import { rewardClient } from "../data/reward-client";
import { rewardClaimFactory } from "./use-claim";
import { paymentRecordFactory } from "./use-payment-record";

const asyncStore = createAsyncStore<RewardOverview>(rewardClient.getRewards.bind(rewardClient));

export const useRewards = () => {
  const rewardOverview = useObservableValue(asyncStore.value$);
  const loading = useObservableValue(asyncStore.loading$);
  const error = useObservableValue(asyncStore.error$);

  useEvent("GLOBAL.PAYMENT.SUCCESS", () => {
    asyncStore.fetchData();
  });

  const fetch = async (profileId?: string) => {
    const res = await asyncStore.fetchData(profileId);
    const pending = res.paymentHistory.filter((p) => p.paymentRecord.status === "PENDING");
    pending.forEach((p) => {
      paymentRecordFactory.poll(p.paymentRecord.id);
    });
    res.userRewards.forEach((ur) => {
      const hist = res.paymentHistory.find(
        (p) => rewards.RewardsService.isPaymentForReward(p.paymentRecord, ur) && p.paymentRecord.status === "PENDING",
      );
      rewardClaimFactory.from({ rewardId: ur.id }, hist?.paymentRecord);
    });
  };

  return {
    fetch,
    rewardOverview,
    isLoading: loading,
    isError: error,
  };
};
