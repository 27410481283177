import { rewards } from "@fscrypto/domain";
import {
  Entity,
  EntityFactory,
  OptionalStore,
  Store,
  createOptionalStore,
  createStore,
  useEntity,
  useOptionalStore,
} from "@fscrypto/state-management";
import { filter, map } from "rxjs";
import { RewardClient, rewardClient } from "../data/reward-client";
import { PaymentRecordEntity, paymentRecordFactory } from "./use-payment-record";

type RewardClaim = {
  rewardId: rewards.UserRewards["id"];
};

type ClaimStatus = "idle" | "pending" | "done" | "timeout" | "error" | "claiming" | "claimError";
class RewardClaimEntity implements Entity<RewardClaim> {
  public readonly id: string;
  store: Store<RewardClaim>;
  statusStore: Store<ClaimStatus>;
  paymentRecordEntityStore: OptionalStore<PaymentRecordEntity>;
  constructor(
    rewardId: string,
    private client: RewardClient,
  ) {
    this.id = rewardId;
    this.store = createStore({ rewardId });
    this.statusStore = createStore("idle" as ClaimStatus);
    this.paymentRecordEntityStore = createOptionalStore();
  }

  async claim(address: string) {
    this.statusStore.set("claiming");
    try {
      const paymentRecordId = await this.client.claimReward({ address, userRewardsRollupId: this.id });
      const entity = await paymentRecordFactory.poll(paymentRecordId);
      this.#setPaymentRecordEntity(entity);
    } catch (e) {
      this.statusStore.set("claimError");
    }
  }

  attachPaymentRecord(paymentRecord: rewards.PaymentRecord) {
    const paymentRecordEntity = paymentRecordFactory.from(paymentRecord);
    this.#setPaymentRecordEntity(paymentRecordEntity);
  }

  #setPaymentRecordEntity(entity: PaymentRecordEntity) {
    entity.statusStore.value$.subscribe((status) => {
      this.statusStore.set(status);
    });
    this.paymentRecordEntityStore.set(entity);
  }

  clear() {
    this.statusStore.set("idle");
  }
}

class RewardClaimEntityFactory implements EntityFactory<RewardClaimEntity> {
  store: Store<RewardClaimEntity[]>;
  constructor(private client: RewardClient) {
    this.store = createStore([] as RewardClaimEntity[]);
  }

  from(rewardClaim: RewardClaim, paymentRecord?: rewards.PaymentRecord) {
    const existing = this.store.get().find((p) => p.id === rewardClaim.rewardId);
    if (existing) {
      return existing;
    }
    const rewardClaimEntity = new RewardClaimEntity(rewardClaim.rewardId, this.client);
    if (paymentRecord) {
      rewardClaimEntity.attachPaymentRecord(paymentRecord);
    }
    this.store.set([...this.store.get(), rewardClaimEntity]);
    return rewardClaimEntity;
  }

  from$(id: string) {
    return this.store.value$.pipe(
      map((rewardClaims) => rewardClaims.find((r) => r.id === id)),
      filter(Boolean),
    );
  }
}

export const rewardClaimFactory = new RewardClaimEntityFactory(rewardClient);

export const useClaim = (rewardId: string) => {
  const rewardClaimEntity = useEntity(rewardClaimFactory, rewardId);
  const paymentRecordEntity = useOptionalStore(rewardClaimEntity?.paymentRecordEntityStore);
  const paymentRecord = useOptionalStore(paymentRecordEntity?.store);
  const status = useOptionalStore(rewardClaimEntity?.statusStore);

  return {
    claim: rewardClaimEntity?.claim.bind(rewardClaimEntity),
    paymentRecord,
    status,
    isIdle: status === undefined || status === "idle",
    isPending: status === "pending",
    isDone: status === "done",
    isTimeout: status === "timeout",
    isErrorClaiming: status === "claimError",
    isClaiming: status === "claiming",
    reset: rewardClaimEntity?.clear.bind(rewardClaimEntity),
  };
};
